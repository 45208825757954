import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderDetails, updateOrder } from "../../redux/orders/orders.thunks";
import {
  selectIsOrdersFailed,
  selectOrderDetails,
  selectIsViewOpen,
  selectIsOrdersLoading
} from "../../redux/orders/orders.selectors";
import { selectCurrency } from "../../redux/session/session.selectors";
import { closeViewModal, openCheckoutModal } from "../../redux/orders/orders.actions";
import { Error } from "../../components/error/error.component";

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import "./order-details.styles.scss";
import { Loading } from "../../components/loading/loading.component";

export const OrderDetails = (props) => {
  //const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const order = useSelector(selectOrderDetails);
  const isFailed = useSelector(selectIsOrdersFailed);
  const open = useSelector(selectIsViewOpen);
  const isLoading = useSelector(selectIsOrdersLoading);
  const currency = useSelector(selectCurrency);

  if (isFailed) {
    return <Error />;
  }
  const onCloseModal = () => {
    //setOpen(false);
    dispatch(closeViewModal());
  };

  const openModal = () => {
    dispatch(fetchOrderDetails(props.orderId, true));
    //setOpen(true);
  };

  const checkout = () => {
    dispatch(openCheckoutModal());
  }

  const openOrder = () => {
    dispatch(updateOrder(order.id, {status: 'new'}));
  }

  return (
    
    <>
        <button onClick={openModal}>View</button>
        <Modal open={open} 
          onClose={onCloseModal} 
          center
          classNames={{
            overlay: 'order-details-modal-overlay',
            modal: 'order-detail-modal',
          }}>
          {isLoading&&<Loading />}
          <div className="details-top">
            <div className="detail-top-left">
              <h4>Order Number {order.id}</h4>
              <div className="order-details-btns order-details-mobile">
                <button>{order.order_mode}</button>
                {/* <button className="checkout-btn">Mobile</button> */}
                <button className={order.status}>{order.status}</button>
              </div>
              {/* <h5>KIOSK 001</h5> */}
              <p><span>Guest Count:</span> {order.guest_count}</p>
              <p><span>Last Updated:</span> {new Date(order.updated_at).toLocaleString()}</p>
              <p><span>Created:</span> {new Date(order.created_at).toLocaleString()}</p>
              <p><span>Waiter:</span> {order.waiter?order.waiter:'-'}</p>
              <p><span>Payment Method:</span> Cash</p>
              <p><span>Notes:</span> {order.note_to_chef?order.note_to_chef:'-'} </p>
            </div>
            <div className="detail-top-right">
              <div className="order-details-btns">
                <button>{order.order_mode}</button>
                {/* <button className="checkout-btn">Mobile</button> */}
                <button className={order.status}>{order.status}</button>
              </div>
              <p><span>Total Amount:</span>&nbsp;{currency}{Number(order.total_bill).toFixed(2)}</p>
              <p><span>Discount:</span>&nbsp;{Number(order.discount)}%</p>
              <p><span>Service charge:</span> {Number(order.service_charge)}%</p>
              <p><span>Tax:</span>&nbsp;{Number(order.tax)}%</p>
              <br />
              <p><span>Tip:</span>&nbsp;{currency}{Number(order.tip).toFixed(2)}</p>
              <p><span>Paid:</span>&nbsp;{currency}{Number(order.paid).toFixed(2)}</p>
              <p><span>Total billed:</span>&nbsp;{currency}{Number(order.total_amount).toFixed(2)}</p>
            </div>
          </div>
          <div className="items-table">
            <div className="item-item title-item">
              <div className="ord-col-2 title-col">
                Item
              </div>
              <div className="ord-col-2 title-col">
                Quantity
              </div>
              <div className="ord-col-3 title-col">
                Unit Price
              </div>
              <div className="ord-col-4 title-col">
                Total
              </div>
              {/* <div className="ord-col-5 title-col">
                Sent
              </div>
              <div className="ord-col-6 title-col">
                Comment
              </div>
              <div className="ord-col-6 title-col">
                Actions
              </div> */}
          </div>
          {order.items.map((item) => (
            <div className="item-item" key={item.id}>
              <div className="ord-col-2 item-name">
                {item.name}
              </div>
              <div className="ord-col-2">
                {item.quantity}
              </div>
              <div className="ord-col-3">
              {currency}{item.unit_price}
              </div>
              <div className="ord-col-4">
              {currency}{item.quantity * item.unit_price}
              </div>
              {/* <div className="ord-col-5">
                {item.id}
              </div>
              <div className="ord-col-6">
                {item.id}
              </div>
              <div className="ord-col-6 actions">
                <img src="images/delete-black-18dp.svg" alt="delete"/>
              </div> */}
            </div>
            ))}
          </div>
          {/* <div>
            <button className="delete-all-btn">Delete All Items</button>
          </div> */}
          <div className="customer-info">
            <h4>Customer Information</h4>
            <p>{order.customer_name}</p>
            <p>{order.customer_phone}</p>
            {/* <p>test@mail.com</p> */}
          </div>
          <div className="footer-btns">
            <button className="cancel-btn" onClick={onCloseModal}>Cancel</button>
            {order.status === 'completed'?<button className="open-btn" onClick={openOrder}>Re-Open</button>:
            <button onClick={checkout}>Checkout</button>}
          </div>
        </Modal>
    </>
  );
};
