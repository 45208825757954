import {
  fetchOrdersStarted,
  fetchOrdersSuccess,
  fetchOrdersFailure,
  fetchOrderDetailsStarted,
  fetchOrderDetailsSuccess,
  fetchOrderDetailsFailure,
  fetchFiltersSuccess,
  deleteOrderStarted,
  deleteOrderSuccess,
  deleteOrderFailure,
  updateOrderStarted,
  updateOrderSuccess,
  updateOrderFailure,
  removeDeletedOrder,
  openViewModal,
  openCheckoutModal,
  closeCheckoutModal
} from "./orders.actions";
import { AppThunk } from "../root-types";
import { OrdersResponse, OrderDetailsResponse, FilterResponse } from "./orders.types";
import axios from "axios";

export const fetchOrdersAndFilters = (filter): AppThunk => async (
  dispatch,
  getState,
  { api }
) => {
  try {
    await dispatch(fetchOrdersStarted());
    let filterSting = "";
    Object.keys(filter).forEach((key) => {
      if(filter[key] !== 'all' && filter[key] !== '') {
        filterSting += `${key}=${filter[key]}&`;
      }
    });
    const orders = await api.get<any>(`/orders/?${filterSting}`);
    const filters = await api.get<any>("/orders/filters/");

    axios.all([orders, filters]).then(axios.spread((...responses) => {
      const { data } = responses[0];
      const data2 = responses[1].data;
      dispatch(fetchOrdersSuccess(data));
      dispatch(fetchFiltersSuccess(data2.data));
      // use/access the results 
    })).catch(errors => {
      console.error(errors);
      dispatch(fetchOrdersFailure());
    })

    
  } catch (error) {
    
  }
};

export const fetchOrders = (filter): AppThunk => async (
  dispatch,
  getState,
  { api }
) => {
  try {
    await dispatch(fetchOrdersStarted());
    let filterSting = "";
    Object.keys(filter).forEach((key) => {
      if(filter[key] !== 'all') {
        filterSting += `${key}=${filter[key]}&`;
      }
    });
    const orders = await api.get<any>(`/orders/?${filterSting}`);

    axios.all([orders]).then(axios.spread((...responses) => {
      const { data } = responses[0];
      dispatch(fetchOrdersSuccess(data));
      // use/access the results 
    })).catch(errors => {
      console.error(errors);
      dispatch(fetchOrdersFailure());
    })

    
  } catch (error) {
    
  }
};

export const fetchOrderDetails = (orderId, isView=true): AppThunk => async (
  dispatch,
  getState,
  { api }
) => {
  try {
    await dispatch(fetchOrderDetailsStarted());

    const { data } = await api.get<OrderDetailsResponse>(`/orders/${orderId}`);

    dispatch(fetchOrderDetailsSuccess(data.data));
    if(isView) {
      dispatch(openViewModal());
    } else {
      dispatch(openCheckoutModal());
    }
  } catch (error) {
    console.error(error);
    dispatch(fetchOrderDetailsFailure());
  }
};

export const deleteOrder = (orderId): AppThunk => async (
  dispatch,
  getState,
  { api }
) => {
  try {
    await dispatch(deleteOrderStarted());

    await api.delete<any>(`/orders/${orderId}`);

    dispatch(deleteOrderSuccess(orderId));
    setTimeout(() => {
      dispatch(removeDeletedOrder(orderId));
    }, 700);
  } catch (error) {
    console.error(error);
    dispatch(deleteOrderFailure());
  }
};

export const updateOrder = (orderId, order): AppThunk => async (
  dispatch,
  getState,
  { api }
) => {
  try {
    await dispatch(updateOrderStarted());

    const { data } = await api.put<any>(`/orders/${orderId}/`, order);

    dispatch(closeCheckoutModal());
    setTimeout(() => {
      dispatch(updateOrderSuccess(data.data));
    }, 700);
  } catch (error) {
    console.error(error);
    dispatch(updateOrderFailure());
  }
};
