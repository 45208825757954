import {
  OrdersState,
  OrdersActionTypes,
  FETCH_ORDERS_STARTED,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_FAILURE,
  FETCH_ORDER_DETAILS_STARTED,
  FETCH_ORDER_DETAILS_FAILURE,
  FETCH_ORDER_DETAILS_SUCCESS,
  FETCH_FILTERS_SUCCESS,
  CLOSE_VIEW_MODAL,
  CLOSE_DELETE_MODAL,
  CLOSE_CHECKOUT_MODAL,
  OPEN_DELETE_MODAL,
  OPEN_VIEW_MODAL,
  OPEN_CHECKOUT_MODAL,
  DELETE_ORDER_SUCCESS,
  REMOVE_DELETED_ORDER,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAILURE,
  DELETE_ORDER_STARTED,
  DELETE_ORDER_FAILURE,
  OrderDetails,
  Filters,
  UPDATE_ORDER_STARTED
} from "./orders.types";

const initialOrderDetails: OrderDetails = {
  id: NaN,
  total_bill: NaN,
  note_to_chef: "",
  description: "",
  order_mode: "",
  restaurant_id: NaN,
  table: NaN,
  created_at: "",
  updated_at: "",
  contact_number: NaN,
  status: "",
  items: [],
  customer_name: "",
  customer_phone: "",
  discount: NaN,
  guest_count: NaN,
  order_number: NaN,
  paid: NaN,
  tax: NaN,
  tip: NaN,
  waiter: "",
  service_charge: NaN,
  total_amount: NaN
}
const initialFilters: Filters = {
  statuses: [{label: 'All', value: 'all', key: 'all', name: 'All'}],
  tables: [{label: 'All', value: 'all', key: 'all', name: 'All'}],
  order_modes: [{label: 'All', value: 'all', key: 'all', name: 'All'}]
}
const initialState: OrdersState = {
  orders: [],
  isLoading: false,
  isFailed: false,
  orderDetails: initialOrderDetails,
  filters: initialFilters,
  isViewOpen: false,
  isCheckoutOpen: false,
  isDeleteOpen: false,
  count: 0
};

export const ordersReducer = (
  state = initialState,
  action: OrdersActionTypes
): OrdersState => {
  switch (action.type) {
    case FETCH_ORDERS_STARTED:
      return {
        ...state,
        isLoading: true,
        isFailed: false,
      };
    case FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        orders: action.payload.data,
        count: action.payload.count
      };
    case FETCH_ORDERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFailed: true,
      };
    case FETCH_ORDER_DETAILS_STARTED:
      return {
        ...state,
        isLoading: true,
        isFailed: false,
      };
    case FETCH_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        orderDetails: action.payload
      };
    case FETCH_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFailed: true,
      };
    case FETCH_FILTERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        filters: addAll(action.payload)
      };
    case CLOSE_VIEW_MODAL:
      return {
        ...state,
        isViewOpen: false
      };
    case CLOSE_DELETE_MODAL:
      return {
        ...state,
        isDeleteOpen: false
      };
    case CLOSE_CHECKOUT_MODAL:
      return {
        ...state,
        isCheckoutOpen: false
      };
    case OPEN_DELETE_MODAL:
      return {
        ...state,
        isDeleteOpen: true
      };
    case OPEN_VIEW_MODAL:
      return {
        ...state,
        isViewOpen: true
      };
    case OPEN_CHECKOUT_MODAL:
      return {
        ...state,
        isViewOpen: false,
        isCheckoutOpen: true
      };
    case DELETE_ORDER_STARTED: 
      return {
        ...state,
        isLoading: true
      };
    case DELETE_ORDER_SUCCESS: 
      return {
        ...state,
        isDeleteOpen: false,
        isLoading: false
      }
    case DELETE_ORDER_FAILURE: 
      return {
        ...state,
        isLoading: false
      }
    case REMOVE_DELETED_ORDER:
      return {
        ...state,
        orders: removeDeleted(state.orders, action.payload),
        count: state.count -1
      }
    case UPDATE_ORDER_STARTED: 
      return {
        ...state,
        isLoading: true
      }
    case UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        orders: updateOrder(state.orders, action.payload),
        isLoading: false,
        isCheckoutOpen: false,
        isViewOpen: false,
        orderDetails: action.payload
      }
    case UPDATE_ORDER_FAILURE: 
      return {
        ...state,
        isLoading: false
      }
    default:
      return state;
  }
};

//Reducer functions

const addAll = (filters) => {
  Object.keys(filters).map((key) => {
    filters[key] = filters[key].map(item => {
      return {...item, label: item.name, value: item.key}
    })
    let allItem = {label: 'All', value: 'all', key: 'all', name: 'All'}
    filters[key] = [allItem, ...filters[key]];
    return filters[key];
  })
  return filters
}

const removeDeleted = (orders, id) => {
  orders = orders.filter(o => o.id !==id);
  return orders;
}

const updateOrder = (orders, update) => {
  const index = orders.findIndex((o) => o.id === update.id);
  Object.keys(orders[index]).forEach(key => {
    orders[index][key] = update[key];
  })
  return [...orders];
}
