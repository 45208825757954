import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsOrdersFailed,
  selectOrderDetails,
  selectIsCheckoutOpen,
  selectIsOrdersLoading
} from "../../redux/orders/orders.selectors";
import { selectCurrency } from "../../redux/session/session.selectors";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import './checkout-order.styles.scss';
import { fetchOrderDetails, updateOrder } from "../../redux/orders/orders.thunks";
import { closeCheckoutModal } from "../../redux/orders/orders.actions";
import { Loading } from "../../components/loading/loading.component";

export const CheckoutOrder = (props) => {
  const dispatch = useDispatch();
  const open = useSelector(selectIsCheckoutOpen);
  const order = useSelector(selectOrderDetails);
  const isFailed = useSelector(selectIsOrdersFailed);
  const isLoading = useSelector(selectIsOrdersLoading);
  const currency = useSelector(selectCurrency);
  const [discount, setDiscount] = useState('-1');
  const [sCharge, setSCharge] = useState('-1');
  const [tax, setTax] = useState('-1');
  const onCloseModal = () => {
    dispatch(closeCheckoutModal());
  };

  const openModal = () => {
    dispatch(fetchOrderDetails(props.orderId, false));
  };

  useEffect(() => {
    setDiscount(String(order.discount));
    setSCharge(String(order.service_charge));
    setTax(String(order.tax));
  }, [order])

  const updateOrders = (checkout=false) => {
    let data = {
      discount: isNaN(Number(discount))?0:Number(discount),
      service_charge: isNaN(Number(sCharge))?0:Number(sCharge),
      tax: isNaN(Number(tax))?0:Number(tax)
    }
    console.log(data);
    if(checkout) {
      data['status'] = 'completed';
    }
    dispatch(updateOrder(order.id, data))
  }

  return (
    <>
        <button className="checkout-btn" onClick={openModal}>Checkout</button>
        <Modal open={open} 
        onClose={onCloseModal} 
        center classNames={{
          overlay: 'customOverlay',
          modal: 'checkout-modal',
        }}>
          {isLoading&&<Loading />}
          <h4>Order Number {order.id}</h4>
          <div className="row">
            <p>Discount</p>
            <div className="input-c">
              <input className="left-icon" type="text" onChange={(e) => setDiscount(e.target.value)} value={discount} placeholder="Discount"/>
              <span className="left-icon-span">{currency}</span>
            </div>
          </div>
          <div className="row">
            <p>Service Charge</p>
            <div className="input-c">
              <input className="right-icon" type="text" onChange={(e) => setSCharge(e.target.value)} value={sCharge} placeholder="Service charge"/>
              <span className="right-icon-span">%</span>
            </div>
          </div>
          <div className="row">
            <p>Tax</p>
            <div className="input-c">
              <input className="right-icon" type="text" onChange={(e) => setTax(e.target.value)} value={tax} placeholder="Tax"/>
              <span className="right-icon-span">%</span>
            </div>
          </div>
          <div className="row">
            <p>Total Amount</p>
            <p>{currency}{order.total_bill.toFixed(2)}</p>
          </div>
          <div className="row">
            <p>Total Billed</p>
            <p>{currency}{(order.total_bill + (order.total_bill/100)*(Number(tax)+Number(sCharge))-Number(discount)).toFixed(2)}</p>
          </div>
          <div className="footer-btns">
            <button className="cancel-btn" onClick={onCloseModal}>Cancel</button>
            <button className="black-btn" onClick={() => updateOrders(false)}>Update</button>
            <button onClick={() => updateOrders(true)}>Update & Checkout</button>
          </div>
        </Modal>
    </>
  );
};
