import {
  FETCH_ORDERS_STARTED,
  FETCH_ORDERS_FAILURE,
  FETCH_ORDERS_SUCCESS,
  OrdersActionTypes,
  Order,
  CLEAN_ORDERS_STATE,
  FETCH_ORDER_DETAILS_STARTED,
  FETCH_ORDER_DETAILS_FAILURE,
  FETCH_ORDER_DETAILS_SUCCESS,
  CLEAN_ORDER_DETAILS_STATE,
  FETCH_FILTERS_SUCCESS,
  CLOSE_VIEW_MODAL,
  CLOSE_CHECKOUT_MODAL,
  CLOSE_DELETE_MODAL,
  OPEN_DELETE_MODAL,
  OPEN_CHECKOUT_MODAL,
  OPEN_VIEW_MODAL,
  DELETE_ORDER_STARTED,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAILURE,
  UPDATE_ORDER_STARTED,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAILURE,
  REMOVE_DELETED_ORDER,
  OrderDetails,
  Filters,
  OrdersResponse
} from "./orders.types";

export const fetchOrdersStarted = (): OrdersActionTypes => ({
  type: FETCH_ORDERS_STARTED,
});

export const fetchOrdersSuccess = (orders: OrdersResponse): OrdersActionTypes => ({
  type: FETCH_ORDERS_SUCCESS,
  payload: orders,
});

export const fetchOrdersFailure = (): OrdersActionTypes => ({
  type: FETCH_ORDERS_FAILURE,
});

export const cleanOrdersState = (): OrdersActionTypes => ({
  type: CLEAN_ORDERS_STATE,
});

export const fetchOrderDetailsStarted = (): OrdersActionTypes => ({
  type: FETCH_ORDER_DETAILS_STARTED,
});

export const fetchOrderDetailsSuccess = (order: OrderDetails): OrdersActionTypes => ({
  type: FETCH_ORDER_DETAILS_SUCCESS,
  payload: order,
});

export const fetchOrderDetailsFailure = (): OrdersActionTypes => ({
  type: FETCH_ORDER_DETAILS_FAILURE,
});

export const cleanOrderDetailsState = (): OrdersActionTypes => ({
  type: CLEAN_ORDER_DETAILS_STATE,
});

export const fetchFiltersSuccess = (filters: Filters): OrdersActionTypes => ({
  type: FETCH_FILTERS_SUCCESS,
  payload: filters
});

export const closeViewModal = (): OrdersActionTypes => ({
  type: CLOSE_VIEW_MODAL
});

export const closeDeleteModal = (): OrdersActionTypes => ({
  type: CLOSE_DELETE_MODAL
});

export const closeCheckoutModal = (): OrdersActionTypes => ({
  type: CLOSE_CHECKOUT_MODAL
});

export const openDeleteModal = (): OrdersActionTypes => ({
  type: OPEN_DELETE_MODAL
});

export const openViewModal = (): OrdersActionTypes => ({
  type: OPEN_VIEW_MODAL
});

export const openCheckoutModal = (): OrdersActionTypes => ({
  type: OPEN_CHECKOUT_MODAL
});

export const deleteOrderStarted = (): OrdersActionTypes => ({
  type: DELETE_ORDER_STARTED
});

export const deleteOrderSuccess = (id: number): OrdersActionTypes => ({
  type: DELETE_ORDER_SUCCESS,
  payload: id
});

export const deleteOrderFailure = (): OrdersActionTypes => ({
  type: DELETE_ORDER_FAILURE
});

export const updateOrderStarted = (): OrdersActionTypes => ({
  type: UPDATE_ORDER_STARTED
});

export const updateOrderSuccess = (order: OrderDetails): OrdersActionTypes => ({
  type: UPDATE_ORDER_SUCCESS,
  payload: order
});

export const updateOrderFailure = (): OrdersActionTypes => ({
  type: UPDATE_ORDER_FAILURE
});

export const removeDeletedOrder = (id: number): OrdersActionTypes => ({
  type: REMOVE_DELETED_ORDER,
  payload: id
});
