import React, { useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import "./delete-order.styles.scss"
import { deleteOrder } from "../../redux/orders/orders.thunks";
import { openDeleteModal, closeDeleteModal } from "../../redux/orders/orders.actions";
import { selectIsDeleteOpen, selectIsOrdersLoading } from "../../redux/orders/orders.selectors";
import { Loading } from "../../components/loading/loading.component";

export const DeleteOrder = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const delteState = useSelector(selectIsDeleteOpen);
  const isLoading = useSelector(selectIsOrdersLoading);
  const onCloseModal = () => {
    setOpen(false);
    dispatch(closeDeleteModal());
  };

  const openModal = () => {
    setOpen(true);
    dispatch(openDeleteModal());
  };

  const deleteOrders = () => {
    dispatch(deleteOrder(props.orderId));
  }

  return (
    <>
        <img className="delete-btn" alt="delete" onClick={openModal} src="images/delete-black-18dp.svg" />
        <Modal open={open && delteState} 
          onClose={onCloseModal} center
          classNames={{
            overlay: 'customOverlay',
            modal: 'customModal',
          }}>
          {isLoading && <Loading />}
          <h5>Delete order {props.orderId}</h5>
          <p>Are you sure you want to delete this order?</p>
          <div className="modal-btns">
            <button className="cancel-btn" onClick={onCloseModal}>Cancel</button>
            <button className="confirm-btn" onClick={deleteOrders}>Confirm</button>
          </div>
        </Modal>
    </>
  );
};
