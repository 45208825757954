import {
  loginStarted,
  loginSuccess,
  loginFailure,
} from "./session.actions";
import { AppThunk } from "../root-types";
import { LoginResponse } from "./session.types";

export const loginAPI = (values): AppThunk => async (
  dispatch,
  getState,
  { api }
) => {
  try {
    await dispatch(loginStarted());

    // let user = {
    //   "email": "sales@ordermydine.com",
    //   "password": "omd123"
    // }

    const { data } = await api.post<LoginResponse>("/login/", values);

    dispatch(loginSuccess(data.data));
    console.log("success");
    // const history = useHistory();
  } catch (error) {
    console.error(error);
    dispatch(loginFailure(error.response.data.message));
  }
};
