export const FETCH_ORDERS_STARTED = "FETCH_ORDERS_STARTED";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAILURE = "FETCH_ORDERS_FAILURE";
export const CLEAN_ORDERS_STATE = "CLEAN_ORDERS_STATE";
export const FETCH_ORDER_DETAILS_STARTED = "FETCH_ORDER_DETAILS_STARTED";
export const FETCH_ORDER_DETAILS_SUCCESS = "FETCH_ORDER_DETAILS_SUCCESS";
export const FETCH_ORDER_DETAILS_FAILURE = "FETCH_ORDER_DETAILS_FAILURE";
export const CLEAN_ORDER_DETAILS_STATE = "CLEAN_ORDER_DETAILS_STATE";
export const FETCH_FILTERS_SUCCESS = "FETCH_FILTERS_SUCCESS";
export const CLOSE_VIEW_MODAL = "CLOSE_VIEW_MODAL";
export const CLOSE_DELETE_MODAL = "CLOSE_DELETE_MODAL";
export const CLOSE_CHECKOUT_MODAL = "CLOSE_CHECKOUT_MODAL";
export const OPEN_DELETE_MODAL = "OPEN_DELETE_MODAL";
export const OPEN_CHECKOUT_MODAL = "OPEN_CHECKOUT_MODAL";
export const OPEN_VIEW_MODAL = "OPEN_VIEW_MODAL";
export const DELETE_ORDER_STARTED = "DELETE_ORDER_STARTED";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const DELETE_ORDER_FAILURE = "DELETE_ORDER_FAILURE";
export const UPDATE_ORDER_STARTED = "UPDATE_ORDER_STARTED";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAILURE = "UPDATE_ORDER_FAILURE";
export const REMOVE_DELETED_ORDER = "REMOVE_DELETED_ORDER";

export interface OrdersState {
  orders: Order[];
  isLoading: boolean;
  isFailed: boolean;
  orderDetails: OrderDetails;
  filters: Filters
  isDeleteOpen: boolean
  isViewOpen: boolean
  isCheckoutOpen: boolean
  count: number
}

interface FetchOrdersStarted {
  type: typeof FETCH_ORDERS_STARTED;
}

interface FetchOrdersSuccess {
  type: typeof FETCH_ORDERS_SUCCESS;
  payload: OrdersResponse;
}

interface FetchOrdersFailure {
  type: typeof FETCH_ORDERS_FAILURE;
}

interface CleanOrdersState {
  type: typeof CLEAN_ORDERS_STATE;
}

interface FetchOrderDetailsStarted {
  type: typeof FETCH_ORDER_DETAILS_STARTED;
}

interface FetchOrderDetailsSuccess {
  type: typeof FETCH_ORDER_DETAILS_SUCCESS;
  payload: OrderDetails;
}

interface FetchOrderDetailsFailure {
  type: typeof FETCH_ORDER_DETAILS_FAILURE;
}

interface CleanOrderDetailsState {
  type: typeof CLEAN_ORDER_DETAILS_STATE;
}

interface fetchFiltersSuccess {
  type: typeof FETCH_FILTERS_SUCCESS;
  payload: Filters
}

interface CloseViewModal {
  type: typeof CLOSE_VIEW_MODAL;
}

interface CloseCheckoutModal {
  type: typeof CLOSE_CHECKOUT_MODAL;
}

interface CloseDeleteModal {
  type: typeof CLOSE_DELETE_MODAL;
}

interface OpenDeleteModal {
  type: typeof OPEN_DELETE_MODAL;
}

interface OpenViewModal {
  type: typeof OPEN_VIEW_MODAL;
}

interface OpenCheckoutModal {
  type: typeof OPEN_CHECKOUT_MODAL;
}

interface DeleteOrderStarted {
  type: typeof DELETE_ORDER_STARTED;
}

interface DeleteOrderSuccess {
  type: typeof DELETE_ORDER_SUCCESS;
  payload: number
}

interface RemoveDeletedOrder {
  type: typeof REMOVE_DELETED_ORDER;
  payload: number
}

interface DeleteOrderFailure {
  type: typeof DELETE_ORDER_FAILURE;
}

interface UpdateOrderStarted {
  type: typeof UPDATE_ORDER_STARTED;
}

interface UpdateOrderSuccess {
  type: typeof UPDATE_ORDER_SUCCESS;
  payload: OrderDetails
}

interface UpdateOrderFailure {
  type: typeof UPDATE_ORDER_FAILURE;
}

export type OrdersActionTypes =
  | FetchOrdersStarted
  | FetchOrdersSuccess
  | FetchOrdersFailure
  | CleanOrdersState
  | FetchOrderDetailsStarted
  | FetchOrderDetailsSuccess
  | FetchOrderDetailsFailure
  | CleanOrderDetailsState
  | fetchFiltersSuccess
  | CloseViewModal
  | CloseCheckoutModal
  | CloseDeleteModal
  | OpenDeleteModal
  | DeleteOrderStarted
  | DeleteOrderSuccess
  | DeleteOrderFailure
  | OpenCheckoutModal
  | OpenViewModal
  | UpdateOrderStarted
  | UpdateOrderSuccess
  | UpdateOrderFailure
  | RemoveDeletedOrder;

export interface Order {
  id: number,
  total_bill: string,
  note_to_chef: string,
  description: string,
  order_mode: string,
  restaurant_id: number,
  table: number,
  created_at: string,
  updated_at: string,
  contact_number: number,
  status: string
  paid: "0"
}

export interface Filters {
  statuses: any[],
  tables: any[],
  order_modes: any[]
}

export interface OrdersResponse {
  data: Order[];
  count: number
}

export interface FilterResponse {
  data: Filters;
}

export interface OrderDetails {
  id: number,
  total_bill: number,
  note_to_chef: string,
  description: string,
  order_mode: string,
  restaurant_id: number,
  table: number,
  created_at: string,
  updated_at: string,
  contact_number: number,
  status: string,
  items: Item[]
  customer_name: string
  customer_phone: string
  discount: number
  guest_count: number
  order_number: number
  paid: number
  tax: number
  tip: number
  waiter: string,
  service_charge: number,
  total_amount: number
}

export interface Item {
  id: number
  name: string
  quantity: number
  unit_price: number
}

export interface OrderDetailsResponse {
  data: OrderDetails;
}
