import {
  LOGIN_STARTED,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  SessionActionTypes,
  User,
  CLEAN_SESSION_STATE,
} from "./session.types";

export const loginStarted = (): SessionActionTypes => ({
  type: LOGIN_STARTED,
});

export const loginSuccess = (user: User): SessionActionTypes => ({
  type: LOGIN_SUCCESS,
  payload: user,
});

export const loginFailure = (message): SessionActionTypes => ({
  type: LOGIN_FAILURE,
  payload: message
});

export const cleanSessionState = (): SessionActionTypes => ({
  type: CLEAN_SESSION_STATE,
});
