import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { Provider } from "react-redux";

import { LoginPage } from "./pages/loginpage/loginpage.component";
import { store } from "./redux/store";
import { HomePage } from "./pages/home-page/home-page.component";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/login" component={LoginPage} />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
