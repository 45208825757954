import { createSelector } from "reselect";

import { RootState } from "../root-types";

const selectOrdersState = (state: RootState) => state.orders;

export const selectOrders = createSelector(
  [selectOrdersState],
  (orders) => orders.orders
);

export const selectIsOrdersLoading = createSelector(
  [selectOrdersState],
  (orders) => orders.isLoading
);

export const selectIsOrdersFailed = createSelector(
  [selectOrdersState],
  (orders) => orders.isFailed
);

export const selectOrderDetails = createSelector(
  [selectOrdersState],
  (orders) => orders.orderDetails
);

export const selectFilters = createSelector(
  [selectOrdersState],
  (orders) => orders.filters
);

export const selectIsViewOpen = createSelector(
  [selectOrdersState],
  (orders) => orders.isViewOpen
);

export const selectIsDeleteOpen = createSelector(
  [selectOrdersState],
  (orders) => orders.isDeleteOpen
);

export const selectIsCheckoutOpen = createSelector(
  [selectOrdersState],
  (orders) => orders.isCheckoutOpen
);

export const selectOrdersCount = createSelector(
  [selectOrdersState],
  (orders) => orders.count
);