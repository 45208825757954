import { createSelector } from "reselect";

import { RootState } from "../root-types";

const selectSessionState = (state: RootState) => state.session;

export const selectUser = createSelector(
  [selectSessionState],
  (session) => session.user
);

export const selectIsLoginLoading = createSelector(
  [selectSessionState],
  (session) => session.isLoading
);

export const selectIsLoginFailed = createSelector(
  [selectSessionState],
  (session) => session.isFailed
);

export const selectToken = createSelector(
  [selectSessionState],
  (session) => session.user.token
);

export const selectCurrency = createSelector(
  [selectSessionState],
  (session) => session.user.restaurant.currency
);

export const selectErrorMessage = createSelector(
  [selectSessionState],
  (session) => session.errorMessage
);
