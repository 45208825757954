import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTimeAgo from 'react-time-ago'
import { fetchOrders, updateOrder, fetchOrdersAndFilters } from "../../redux/orders/orders.thunks";
import {
  selectOrders,
  selectIsOrdersFailed,
  selectFilters,
  selectOrdersCount,
  selectIsOrdersLoading
} from "../../redux/orders/orders.selectors";
import { selectUser } from "../../redux/session/session.selectors"
import { selectToken } from "../../redux/session/session.selectors";
import { Error } from "../../components/error/error.component";
import { cleanOrdersState, updateOrderStarted } from "../../redux/orders/orders.actions";
import { cleanSessionState } from "../../redux/session/session.actions";
import Select from 'react-select';
import Pagination from "react-js-pagination";
import { OrderDetails } from "../order-details/order-details.component";
import { DeleteOrder } from "../delete-order/delete-order.component";
import { CheckoutOrder } from "../checkout-order/checkout-order.component";

import "./home-page.styles.scss";
import { Redirect } from "react-router";
import { Loading } from "../../components/loading/loading.component";

const pageOptions = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
  { value: 40, label: 40 },
  { value: 50, label: 50 }
];

export const HomePage = () => {
  const allFilter = {label: "All", value: "all", key: "all", name: "All"};
  const dispatch = useDispatch();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(pageOptions[0]);
  const [status, setStatus] = useState(allFilter);
  const [tables, setTables] = useState(allFilter);
  const [orderMode, setOrderMode] = useState(allFilter);
  const [sort, setSort] = useState({item: '', value:''});
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    const filters = {
      offset: offset*limit.value,
      limit: limit.value,
      table_id: tables.value,
      status: status.value,
      order_mode: orderMode.value
    }
    const user = localStorage.getItem('user');
    if(user) {
      dispatch(fetchOrdersAndFilters(filters));
    }

    return () => {
      dispatch(cleanOrdersState());
    };
  }, [dispatch]);

  const orders = useSelector(selectOrders);
  const isFailed = useSelector(selectIsOrdersFailed);
  const isLoading = useSelector(selectIsOrdersLoading);
  const token = useSelector(selectToken);
  const filters = useSelector(selectFilters);
  const count = useSelector(selectOrdersCount);
  const user = useSelector(selectUser);

  const logout = () => {
    dispatch(cleanSessionState());
  }

  const fetchOrdersList = (current={}) => {
    let filters = {
      offset: offset*limit.value,
      limit: limit.value,
      table_id: tables.value,
      status: status.value,
      order_mode: orderMode.value
    }
    filters = {...filters, ...current};
    dispatch(fetchOrders(filters));
  }

  const pageChange = (page) => {
    setOffset(page);
    fetchOrdersList({offset: page*limit.value});
  }

  const updateButtonClicked = () => {
    setOffset(0);
    fetchOrdersList({offset: 0});
  }

  const statusChanged = (stat) => {
    setStatus(stat);
    setOffset(0);
    fetchOrdersList({offset: 0, status: stat.value});
  }

  const orderModeChanged = (mode) => {
    setOrderMode(mode);
    setOffset(0);
    fetchOrdersList({offset: 0, order_mode: mode.value});
  }

  const tableChanged = (tab) => {
    setTables(tab);
    setOffset(0);
    fetchOrdersList({offset: 0, table_id: tab.value});
  }

  const limitChange = (limit) => {
    setLimit(limit);
    fetchOrdersList({limit: limit.value});
  }

  const reopenOrder = (orderid) => {
    dispatch(updateOrder(orderid, {status: 'new'}))
  }

  const updateSorting = (i, v) => {
    setSort({item: i, value: v});
    setOffset(0);
    fetchOrdersList({offset: 0, sort: i, order: v});
  }

  if(!token) {
    return <Redirect to="/login" />
  }


  if (isFailed) {
    return <Error />;
  }

  return (
    <div className="home-page">
      {isLoading&&<Loading />}
      <div className="header">
        <img className="menu-icon" alt="menu" src={menu?'images/menu_open-white.svg':'images/menu-white.svg'} onClick={() => setMenu(!menu)} />
        <img className="header-logo" alt="logo" src="images/logo.svg" />
      </div>
      <div className={`sidenav${menu?" opened-menu": ''}`}>
        <div className="logo">
          <img alt="logo" src="images/logo.svg" />
        </div>
        <div className="profile">
          <img alt="pic" src="images/prof-dummy.png" />
          <p>{user.first_name} {user.last_name}</p>
        </div>
        <div className="menu">
          <div className="menu-item active">
            <span>
              <img alt="orders" src="images/orders.svg" />
              Orders
            </span>
          </div>
          <div className="menu-item">
          <span onClick={logout}>
              <img alt="orders" src="images/logout.svg" />
              Sign Out
            </span>
          </div>
        </div>
      </div>
      {menu && <div className="sidenav-overlay" onClick={()=> setMenu(false)}></div>}
      <div className="home-content">
        <h2>Orders</h2>
        <div className="filter-area">
          <div className="filter-row">
            <div className="select-container mob-left">
              <p>Status</p>
              <Select
                options={filters.statuses}
                value= {status}
                onChange = {(value) => {statusChanged(value)}}
              />
            </div>
            <div className="select-container input-field-container mob-right">
              <img alt="search" src="images/search-black-18dp.svg" />
              <input type="text" />
            </div>
          </div>
          <div className="filter-row">
            <div className="select-container mob-left">
              <p>Order Mode</p>
              <Select
                options={filters.order_modes}
                value= {orderMode}
                onChange = {(value) => {orderModeChanged(value)}}
              />
            </div>
            <div className="select-container mob-right">
              <p>Table</p>
              <Select
                options={filters.tables}
                value= {tables}
                onChange = {(value) => {tableChanged(value)}}
              />
            </div>
          </div>
          <div className="mobile-sort">
            <p>Sort By</p>
            <span>
              Status
              {sort.item !== 'status' && <img alt="unsort" onClick={() => updateSorting('status', 'asc')} src="images/arrow-unsorted.svg" />}
              {sort.item === 'status' && <>
                  {sort.value === 'des' && <img onClick={() => updateSorting('', '')} alt="sort-down" src="images/arrow-sortdown.svg" /> }
                  {sort.value === 'asc' && <img onClick={() => updateSorting('status', 'des')} alt="sort-up" src="images/arrow-sortup.svg" /> }
              </>}
            </span>
            <span>
              Type
              {sort.item !== 'order_mode' && <img alt="unsort" onClick={() => updateSorting('order_mode', 'asc')} src="images/arrow-unsorted.svg" />}
              {sort.item === 'order_mode' && <>
                  {sort.value === 'des' && <img onClick={() => updateSorting('', '')} alt="sort-down" src="images/arrow-sortdown.svg" /> }
                  {sort.value === 'asc' && <img onClick={() => updateSorting('order_mode', 'des')} alt="sort-up" src="images/arrow-sortup.svg" /> }
              </>}
            </span>
            <span>
              Update Time
              {sort.item !== 'updated_at' && <img alt="unsort" onClick={() => updateSorting('updated_at', 'asc')} src="images/arrow-unsorted.svg" />}
              {sort.item === 'updated_at' && <>
                  {sort.value === 'des' && <img onClick={() => updateSorting('', '')} alt="sort-down" src="images/arrow-sortdown.svg" /> }
                  {sort.value === 'asc' && <img onClick={() => updateSorting('updated_at', 'des')} alt="sort-up" src="images/arrow-sortup.svg" /> }
              </>}
            </span>
            <span>
              Sent Time
              {sort.item !== 'created_at' && <img alt="unsort" onClick={() => updateSorting('created_at', 'asc')} src="images/arrow-unsorted.svg" />}
              {sort.item === 'created_at' && <>
                  {sort.value === 'des' && <img onClick={() => updateSorting('', '')} alt="sort-down" src="images/arrow-sortdown.svg" /> }
                  {sort.value === 'asc' && <img onClick={() => updateSorting('created_at', 'des')} alt="sort-up" src="images/arrow-sortup.svg" /> }
              </>}
            </span>
          </div>
          <button className="update-button" onClick={updateButtonClicked}><img alt="R" src="images/refresh-white.svg"/>Refresh</button>
        </div>
        <div className="order-table">
          <div className="order-item title-item">
            <div className="ord-col-1 title-col">
              Status
              {sort.item !== 'status' && <img alt="unsort" onClick={() => updateSorting('status', 'asc')} src="images/arrow-unsorted.svg" />}
              {sort.item === 'status' && <>
                  {sort.value === 'des' && <img onClick={() => updateSorting('', '')} alt="sort-down" src="images/arrow-sortdown.svg" /> }
                  {sort.value === 'asc' && <img onClick={() => updateSorting('status', 'des')} alt="sort-up" src="images/arrow-sortup.svg" /> }
              </>}
            </div>
            <div className="ord-col-2 title-col">
              Type
              {sort.item !== 'order_mode' && <img alt="unsort" onClick={() => updateSorting('order_mode', 'asc')} src="images/arrow-unsorted.svg" />}
              {sort.item === 'order_mode' && <>
                  {sort.value === 'des' && <img onClick={() => updateSorting('', '')} alt="sort-down" src="images/arrow-sortdown.svg" /> }
                  {sort.value === 'asc' && <img onClick={() => updateSorting('order_mode', 'des')} alt="sort-up" src="images/arrow-sortup.svg" /> }
              </>}
            </div>
            <div className="ord-col-3 title-col">
              Order Number
            </div>
            <div className="ord-col-4 title-col">
              Table
            </div>
            <div className="ord-col-5 title-col">
              Update Time
              {sort.item !== 'updated_at' && <img alt="unsort" onClick={() => updateSorting('updated_at', 'asc')} src="images/arrow-unsorted.svg" />}
              {sort.item === 'updated_at' && <>
                  {sort.value === 'des' && <img onClick={() => updateSorting('', '')} alt="sort-down" src="images/arrow-sortdown.svg" /> }
                  {sort.value === 'asc' && <img onClick={() => updateSorting('updated_at', 'des')} alt="sort-up" src="images/arrow-sortup.svg" /> }
              </>}
            </div>
            <div className="ord-col-6 title-col">
              Sent Time
              {sort.item !== 'created_at' && <img alt="unsort" onClick={() => updateSorting('created_at', 'asc')} src="images/arrow-unsorted.svg" />}
              {sort.item === 'created_at' && <>
                  {sort.value === 'des' && <img onClick={() => updateSorting('', '')} alt="sort-down" src="images/arrow-sortdown.svg" /> }
                  {sort.value === 'asc' && <img onClick={() => updateSorting('created_at', 'des')} alt="sort-up" src="images/arrow-sortup.svg" /> }
              </>}
            </div>
            <div className="ord-col-7 title-col">
              Actions
            </div>
          </div>
          {orders.map((order) => (
          <div className="order-item" key={order.id}>
            <div className="mob-content status-badges">
              <span className="blue-badge">{order.order_mode?order.order_mode:"-"}</span>
              <span className={`blue-badge ${order.status}-badge`}>{order.status?order.status:"-"}</span>
            </div>
            <div className="ord-col-1 non-mob">
              {order.status?order.status:"-"}
            </div>
            <div className="ord-col-2 non-mob">
              {order.order_mode?order.order_mode:"-"}
            </div>
            <div className="ord-col-3">
              <span className="mob-content">Order Number:&nbsp;</span>{order.id?order.id:"-"}
            </div>
            <div className="ord-col-4">
              <span className="mob-content">Table:&nbsp;</span>{order.table?order.table:"-"}
            </div>
            <div className="ord-col-5">
              <span className="mob-content">Update time:&nbsp;</span><ReactTimeAgo date={new Date(order.updated_at)}/>
            </div>
            <div className="ord-col-6">
              <span className="mob-content">Sent time:&nbsp;</span><ReactTimeAgo date={new Date(order.created_at)}/>
            </div>
            <div className="ord-col-7 actions">
              <OrderDetails orderId={order.id}/>
              {order.status==='completed'?<button onClick={() => reopenOrder(order.id)} className="open-btn">Re-Open</button>:
              <CheckoutOrder orderId={order.id}/>}
              <DeleteOrder orderId={order.id}/>
            </div>
          </div>
          ))}
          {orders.length<=0 && <div className="no-orders">No orders</div>}
        </div>
        <div className="mob-content pagination-count-data">
          {`Displaying ${offset*limit.value+1}-${offset*limit.value+limit.value<count?offset*limit.value+limit.value:count} 0f ${count} records`}
        </div>
        <div className="pagination-container">
          <Pagination
            activePage={offset+1}
            itemsCountPerPage={limit.value}
            totalItemsCount={count}
            pageRangeDisplayed={5}
            onChange={(page)=>{pageChange(page-1)}}
            hideFirstLastPages={true}
            prevPageText="Previous"
            nextPageText="Next"
            itemClassPrev="prev-item"
            itemClassNext="next-item"
          />
          <div className="pagination-details">
            <div className = "pagination-counts">
              <Select
                options={pageOptions}
                value= {limit}
                onChange = {(value) => {limitChange(value)}}
                menuPlacement = "top"
              />
            </div>
            <span>{`Displaying ${offset*limit.value+1}-${offset*limit.value+limit.value<count?offset*limit.value+limit.value:count} 0f ${count} records`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
