export const LOGIN_STARTED = "LOGIN_STARTED";
export const LOGIN_SUCCESS = "FETCH_POSTS_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const CLEAN_SESSION_STATE = "CLEAN_SESSION_STATE";

export interface SessionState {
  isLoading: boolean;
  isFailed: boolean;
  user: User;
  errorMessage: string;
}

interface LoginStarted {
  type: typeof LOGIN_STARTED;
}

interface LoginSuccess {
  type: typeof LOGIN_SUCCESS;
  payload: User;
}

interface LoginFailure {
  type: typeof LOGIN_FAILURE;
  payload: string
}

interface CleanSessionState {
  type: typeof CLEAN_SESSION_STATE;
}

export type SessionActionTypes =
  | LoginStarted
  | LoginSuccess
  | LoginFailure
  | CleanSessionState;

export interface Restaurant {
  currency: string;
}

export interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  token: string;
  restaurant: Restaurant
}

export interface LoginResponse {
  data: User;
}
