import {
  SessionState,
  SessionActionTypes,
  User,
  LOGIN_STARTED,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  CLEAN_SESSION_STATE
} from "./session.types";

const initialRestaurant = {
  currency: ""
}

const initialUser: User = {
  id: "",
  first_name: "",
  last_name: "",
  email: "",
  token: "",
  restaurant: initialRestaurant
}
const localUser = localStorage.getItem("user");
const userValue = localUser?JSON.parse(String(localUser)):initialUser;
const initialState: SessionState = {
  user: userValue,
  isLoading: false,
  isFailed: false,
  errorMessage: ""
};

export const sessionReducer = (
  state = initialState,
  action: SessionActionTypes
): SessionState => {
  switch (action.type) {
    case LOGIN_STARTED:
      return {
        ...state,
        isLoading: true,
        isFailed: false,
        errorMessage: ""
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("user", JSON.stringify(action.payload));
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        isLoading: false,
        user: action.payload,
        errorMessage: ""
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFailed: true,
        errorMessage: action.payload
      };
    case CLEAN_SESSION_STATE:
      localStorage.clear();
      return {
        ...state,
        user: initialUser
      }
    default:
      return state;
  }
};
