import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import * as Yup from "yup";
import { loginAPI } from "../../redux/session/session.thunks";
import {
  selectErrorMessage,
  selectIsLoginLoading,
  selectToken
} from "../../redux/session/session.selectors";

import "./loginpage.styles.scss";
import { Redirect } from "react-router";

export const LoginPage = () => {
  const dispatch = useDispatch();
  const errorMessage = useSelector(selectErrorMessage);
  const isLoading = useSelector(selectIsLoginLoading);
  const token = useSelector(selectToken);
  const login = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      //alert(JSON.stringify(values));
      dispatch(loginAPI(values));
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required!"),
      password: Yup.string()
        .required("Password is required!"),
    }),
  });

  if(token){
    return ( <Redirect to="/" ></Redirect>);
  }

  return (
  <>
    <Helmet>
      <title>Panel OrderMyDine | Dashboard</title>
    </Helmet>
    <div className="login-page">
      <div className="form-main-container">
        <div className="logo-section">
          <div className="logo-container">
            <img
              alt="logo"
              className="logo"
              src="images/logo.svg"
            />
          </div>
        </div>
        <div className="form-section">
          <form onSubmit={login.handleSubmit} noValidate>
            <div className="mobile-logo">
              <img
                alt="logo"
                className="logo"
                src="images/logo-black.svg"
              />
            </div>
            <label htmlFor="email"></label>{" "}
            <input
              id="email"
              name="email"
              type="email"
              placeholder="User name"
              onChange={login.handleChange}
              value={login.values.email}
            />
            {login.touched.email && login.errors.email ? (
              <div className="error-messge">{login.errors.email}</div>
            ) : null}
            <br /> <br />
            <label htmlFor="password"></label>{" "}
            <input
              id="password"
              name="password"
              type="password"
              placeholder="Password"
              onChange={login.handleChange}
              value={login.values.password}
            />
            {login.touched.password && login.errors.password ? (
              <div className="error-messge">{login.errors.password}</div>
            ) : null}
            {errorMessage?<div className="error-messge">{errorMessage}</div>:null}
            <br />
            <br />
            <button type="submit">Submit  &#8594;</button>
          </form>
        </div>
      </div>
    </div>
  </>
  )
};
